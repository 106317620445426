export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGGED_USER = "LOGGED_USER";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const PAGE_LOADING = "PAGE_LOADING";
export const SIDEBAR_SHOW = "SIDEBAR_SHOW";
export const PARKING_LIST = "PARKING_LIST";
export const PRINT_DATA = "PRINT_DATA";
export const ALL_LOCATIONS = "ALL_LOCATIONS";
export const ALL_BRANCHES = "ALL_BRANCHES";
export const LOAD_BRANCH = "LOAD_BRANCH";
