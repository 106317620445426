import React from "react";
import { Field, reduxForm } from "redux-form";
import { CButton } from "@coreui/react";
import { InputField, SelectField, CheckboxField } from "../../_components";
import { Validators } from "../../_helpers";
const LocationForm = (props) => {
  const { handleSubmit, addLocation, closeForm } = props;

  return (
    <form onSubmit={handleSubmit(addLocation)} className="row">
      <Field
        name="LocationName"
        label="Location name"
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
        fieldProps={{
          required: true,
          type: "text",
        }}
      />
      <Field
        name="ShortName"
        type="text"
        label="Short Name"
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
        required
      />
      <Field
        name="BranchId"
        label="Branch "
        component={SelectField}
        values={{ a: 1, b: 1 }}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
        required
      />
      <Field
        name="FirstAddress"
        type="text"
        label="Address Line 1 "
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="LastAddress"
        type="text"
        label="Address Line 2 "
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="City"
        type="text"
        label="City "
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="Pincode"
        type="text"
        label="Pincode "
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="ContactPerson"
        type="text"
        label="Contact Person "
        required
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="ContactPersonEmail"
        type="email"
        label="Contact Email"
        required
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="ContactPersonPhone"
        type="text"
        label="Contact Phone"
        required
        maxLength="10"
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="Status"
        label="Status"
        checkboxText="Active"
        component={CheckboxField}
        id="status"
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <div className="col-sm-12 text-center">
        <CButton type="submit" color="success">
          Save
        </CButton>
        <CButton
          type="button"
          style={{ marginLeft: "10px" }}
          color="danger"
          onClick={(e) => {
            e.preventDefault();
            closeForm();
          }}
        >
          Cancel
        </CButton>
      </div>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "LocationName",
    "ShortName",
    "BranchId",
    "ContactPerson",
    "ContactPersonPhone",
    "ContactPersonEmail",
  ];
  requiredFields.map((fld) => {
    if (Validators.empty(values[fld])) {
      errors[fld] = "Required";
    }
  });
  if (
    !Validators.isEmail(values["ContactPersonEmail"]) &&
    !Validators.empty(values["ContactPersonEmail"])
  ) {
    errors["ContactPersonEmail"] = "Invalid Email";
  }
  return errors;
};

export default reduxForm({
  form: "addLocation",
  validate,
})(LocationForm);
