import React from 'react'
import { connect } from 'react-redux'
import { CContainer, CRow, CCol, CCard, CCardBody, CCardHeader } from '@coreui/react'
import LoginForm from './LoginForm'
import { validateLogin } from '../../_services'
import { LoginError, loggedUser, loginLoading } from '../../_actions'

// import assets/styles
import shortLogo from '../../_assets/img/short-logo.png'
import './Login.css'

class LoginComponent extends React.Component {
    constructor(props) {
        super(props)
        this.loginSubmit = this.loginSubmit.bind(this)
        if (localStorage.getItem('ymsuser')) {
            this.props.history.push('/dashboard')
       }
    }

    async loginSubmit(values) {
        const {
            dispatchLoggedUser,
            dispatchLoginError,
            dispatchLoginLoading
        } = this.props;

        // API CALL
        dispatchLoginLoading(true)
        const user = await validateLogin(values)
        dispatchLoginLoading(false)

        if (user) {
            dispatchLoggedUser(user)
            dispatchLoginError('')
            return this.props.history.push('/dashboard')
        } else {
            dispatchLoginError('Username or Password is invalid')
        }
    }

    render() {
        return (
            <div className="min-vh-100 d-flex flex-row align-items-center login-page">
                <CContainer>
                    <CRow className="justify-content-center">
                        <CCol sm="8" md="6" lg="4">
                            <p className="text-center"><img src={shortLogo} alt="JP" /></p>
                            <CCard className="border-0 shadow">
                                <CCardHeader className="bg-warning text-center">
                                    <h5>Login</h5>
                                </CCardHeader>
                                <CCardBody>
                                    <LoginForm
                                        onSubmit={this.loginSubmit}
                                        loginError={this.props.loginError}
                                        loginLoading={this.props.loginLoading}
                                    />
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CContainer>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loginError: state.alert.loginError,
        loggedUser: state.user.loggedUser,
        loginLoading: state.loading.loginLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchLoginError: payload => { dispatch(LoginError(payload)) },
        dispatchLoggedUser: payload => { dispatch(loggedUser(payload)) },
        dispatchLoginLoading: payload => { dispatch(loginLoading(payload)) },
    }
}

export const Login = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginComponent)