import React from 'react'
import { CSidebarNav } from '@coreui/react'
// import { Link } from 'react-router-dom'
// import { FiHome, FiCornerLeftDown, FiCornerRightUp, FiArchive } from 'react-icons/fi'
import navMenus from './navMenu'
import { AppSidebarNav } from './AppSidebarNav'

export function AppSidebar(props) {
    return (
        <CSidebarNav>
            <AppSidebarNav items={navMenus} />
            {/* <CNavItem>
                <Link to="/home" className="nav-link shadow">
                    <FiHome />  <span>Home</span>
                </Link>
                <Link to="/parking-list" className="nav-link shadow">
                    <FiCornerLeftDown /> <span>Inwards</span>
                </Link>
                <Link to="/parking-list" className="nav-link shadow">
                    <FiCornerRightUp /> <span>Outwards</span>
                </Link>
                <Link to="/parking-list" className="nav-link shadow">
                    <FiArchive /> <span>Stock</span>
                </Link>
            </CNavItem> */}
        </CSidebarNav>
    )
}
