import React from 'react'
import { connect } from 'react-redux'
import { CCard, CCardBody, CRow, CCol, CCardHeader, CCardText } from '@coreui/react'
import { FiCornerLeftDown, FiCornerRightUp, FiArchive } from 'react-icons/fi'

import { parking, pageLoading } from '../../_actions'


class DashboardComponent extends React.Component {

    render() {
        return (
            <CRow className="justify-content-around mt-4">

                <CCol sm="3" className="mb-3">
                    <CCard color="success" textColor="white">
                        <CCardHeader className="text-center">
                            <FiArchive size="60" />
                        </CCardHeader>
                        <CCardBody className="text-center">
                            <CCardText className="fs-2">
                                34345
                            </CCardText>
                        </CCardBody>
                    </CCard>
                </CCol>


                <CCol sm="3" className="mb-3">
                    <CCard color="primary" textColor="white">
                        <CCardHeader className="text-center">
                            <FiCornerLeftDown size="60" />
                        </CCardHeader>
                        <CCardBody className="text-center">
                            <CCardText className="fs-2">
                                34345
                            </CCardText>
                        </CCardBody>
                    </CCard>
                </CCol>

                <CCol sm="3" className="mb-3">
                    <CCard color="danger" textColor="white">
                        <CCardHeader className="text-center">
                            <FiCornerRightUp size="60" />
                        </CCardHeader>
                        <CCardBody className="text-center">
                            <CCardText className="fs-2">
                                34345
                            </CCardText>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        )

    }
}

const mapStateToProps = state => {
    return {
        parkingList: state.parking.parkingList,
        // pageLoading: state.loading.pageLoading
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatchParkingList: payload => { dispatch(parking(payload)) },
        dispatchPageLoading: payload => { dispatch(pageLoading(payload)) }
    }
}

export const Dashboard = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardComponent)