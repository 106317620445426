import React from "react";
import {
  FaTachometerAlt,
  FaParking,
  FaUserShield,
  FaRegChartBar,
  FaBuilding,
  FaLocationArrow,
  FaUsers,
  FaUserTie,
  FaUsersCog,
  FaBalanceScale,
  FaHistory,
  FaReceipt,
  FaSignOutAlt,
  FaSignInAlt,
} from "react-icons/fa";

const _nav = [
  {
    component: "CNavItem",
    name: "Dashboard",
    to: "/dashboard",
    icon: <FaTachometerAlt />,
  },
  {
    component: "CNavItem",
    name: "Inward",
    to: "/parking",
    icon: <FaParking />,
  },
  {
    component: "CNavGroup",
    name: "Admin",
    icon: <FaUserShield />,
    items: [
      {
        component: "CNavItem",
        name: "Branch",
        to: "/branch",
        icon: <FaBuilding />,
      },
      {
        component: "CNavItem",
        name: "Location",
        to: "/location",
        icon: <FaLocationArrow />,
      },
      {
        component: "CNavItem",
        name: "Client",
        to: "/client",
        icon: <FaUserTie />,
      },
      {
        component: "CNavItem",
        name: "Agency",
        to: "/Agency",
        icon: <FaUsersCog />,
      },
      {
        component: "CNavItem",
        name: "Users",
        to: "/users",
        icon: <FaUsers />,
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Reports",
    icon: <FaRegChartBar />,
    items: [
      {
        component: "CNavItem",
        name: "Stock Report",
        to: "/url",
        icon: <FaBalanceScale />,
      },
      {
        component: "CNavItem",
        name: "Vechicle History",
        to: "/url",
        icon: <FaHistory />,
      },
      {
        component: "CNavItem",
        name: "Invoice",
        to: "/url",
        icon: <FaReceipt />,
      },
      {
        component: "CNavItem",
        name: "Outward Report",
        to: "/url",
        icon: <FaSignOutAlt />,
      },
      {
        component: "CNavItem",
        name: "Inward Report",
        to: "/url",
        icon: <FaSignInAlt />,
      },
    ],
  },
];

export default _nav;
