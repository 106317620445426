import { PARKING_LIST, PRINT_DATA } from '../_constants'
export function parking(payload) {
    return {
        type: PARKING_LIST,
        payload
    }
}

export function printData(payload) {
    return {
        type: PRINT_DATA,
        payload
    }
}