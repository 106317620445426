import { SIDEBAR_SHOW, LOGIN_LOADING, PAGE_LOADING } from '../_constants'

const initialState = {
    loginLoading: false,
    pageLoading: false,
    sidebarShow: window.innerWidth > 768 ? true : false
}

export const loading = (state = initialState, action) => {
    if (action.type === LOGIN_LOADING) {
        return { ...state, loginLoading: action.payload }
    } else if (action.type === PAGE_LOADING) {
        return { ...state, pageLoading: action.payload }
    } else if (action.type === SIDEBAR_SHOW) {
        return { ...state, sidebarShow: action.payload }
    }
    return state;
}