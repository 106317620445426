import { API_URL } from '../_constants'
import axios from 'axios'

export const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export async function validateLogin(credentials) {
    
    return axios.post(API_URL + 'users/token', credentials,{
            headers: {
                "Content-Type":"application/json",
                "Accept":"application/json",
            }
        })
        .then(function (response) {
            const user = JSON.stringify(response.data.data.user)
            localStorage.setItem('ymsuser', user)
            localStorage.setItem('ymsadmintoken', response.data.data.token)
            return user;
        })
        .catch(function (error) {
            console.log(error);
        });

}


export function logout(history) {
    localStorage.removeItem('ymsuser')
    history.push('/login')
}