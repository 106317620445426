import { ALL_BRANCHES, LOAD_BRANCH } from "../_constants";

export function allBranches(payload) {
  return {
    type: ALL_BRANCHES,
    payload,
  };
}

export function loadBranch(payload) {
  return {
    type: LOAD_BRANCH,
    payload,
  };
}
