import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
} from "@coreui/react";
import { RiCloseFill } from "react-icons/ri";
import { PageLoader } from "../../_components";
import { allLocations, pageLoading } from "../../_actions";
import LocationForm from "./LocationForm";
// import { LocationList } from './LocationList'
import { getAllLocations, createLocation } from "../../_services";
import { FloatingButton } from "../../_components";

export const Location = () => {
  const locationList = useSelector((state) => state.locations.allLocations);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  async function addLocation(values) {
    dispatch(pageLoading(true));
    const result = await createLocation(values);
    if (result && result.success) {
      const locations = await getAllLocations();
      dispatch(allLocations(locations));
      setVisible(false);
    } else {
      //show alert
      console.log("error in add");
    }
    dispatch(pageLoading(false));
  }

  useEffect(() => {
    async function asyncTasks() {
      dispatch(pageLoading(true));
      const locations = await getAllLocations();
      dispatch(allLocations(locations));
      dispatch(pageLoading(false));
    }
    asyncTasks();
  }, []);

  const { data, columns } = locationList;
  const options = { selectableRows: "none", responsive: "vertical" };

  return (
    <PageLoader>
      <FloatingButton onClick={() => setVisible(!visible)} />
      <MUIDataTable
        title={"Locations"}
        data={data}
        columns={columns}
        options={options}
      />

      <CModal visible={visible} size="xl" onClose={() => setVisible(false)}>
        <CModalHeader>
          <CModalTitle>Location</CModalTitle>
          <CButton color="" onClick={() => setVisible(false)}>
            <RiCloseFill />
          </CButton>
        </CModalHeader>
        <CModalBody>
          <LocationForm
            addLocation={addLocation}
            closeForm={() => setVisible(false)}
          />
        </CModalBody>
      </CModal>
    </PageLoader>
  );
};
