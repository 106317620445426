import React from 'react'
import {connect} from 'react-redux'
import {  CSpinner } from '@coreui/react'

const PageLoaderComponent = props =>{
    if (props.pageLoading) {
        return (
            <div className="children">
                {props.children}
                <div className="h-100 d-flex justify-content-center align-items-center page-loader">
                    <CSpinner/>
                </div>
            </div>
        );
    } else {
        return props.children;
    }
}

const mapStateToProps = state=>{
    return{
        pageLoading: state.loading.pageLoading
    }
}

export const PageLoader = connect(
    mapStateToProps,
    null
)(PageLoaderComponent)