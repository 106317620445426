import React from "react";
import { FaPlus } from "react-icons/fa";
import "./FloatingButton.css";
export function FloatingButton(props) {
  return (
    <a class="float" {...props}>
      <FaPlus />
    </a>
  );
}
