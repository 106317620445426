import { SIDEBAR_SHOW, LOGIN_LOADING, PAGE_LOADING } from '../_constants'

export function loginLoading(payload) {
    return {
        type: LOGIN_LOADING,
        payload
    }
}

export function pageLoading(payload) {
    return {
        type: PAGE_LOADING,
        payload
    }
}

export function sidebarShow(payload) {
    return {
        type: SIDEBAR_SHOW,
        payload
    }
}