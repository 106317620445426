import React from "react";
import { CFormLabel } from "@coreui/react";

export function ErrorDisplay(props) {
  let { touched, error, warning } = props.field?.meta;
  return (
    <>
      {touched && error && <span className="text-danger">{error}</span>}
      {touched && warning && <span className="text-warning">{warning}</span>}
    </>
  );
}

export function InputField(field) {
  const { responsive, className } = field;
  return (
    <div className={(responsive || "") + " mb-3 from-group"}>
      <CFormLabel htmlFor={field?.name}>
        {field?.label}
        {field?.fieldProps?.required ? (
          <span style={{ color: "red" }}>*</span>
        ) : (
          ""
        )}
      </CFormLabel>
      <input
        {...field?.input}
        {...field?.fieldProps}
        placeholder={field?.label}
        className={"form-control " + (className || "")}
      />
      <ErrorDisplay field={field} />
    </div>
  );
}

export function SelectField(field) {
  const responsive = field?.responsive || "";
  return (
    <div className={responsive + " mb-3 from-group"}>
      <CFormLabel htmlFor={field?.name}>
        {field?.label}
        {field?.required ? <span style={{ color: "red" }}>*</span> : ""}
      </CFormLabel>
      <select
        {...field?.input}
        placeholder={field?.label}
        className={"form-control " + field?.className}
      >
        <option value="">Select</option>
        {Object.keys(field?.values).map((val) => {
          return (
            <option key={val} value={val}>
              {field?.values[val]}
            </option>
          );
        })}
      </select>
      <ErrorDisplay field={field} />
    </div>
  );
}

export function CheckboxField(field) {
  const responsive = field?.responsive || "";
  return (
    <div className={responsive + " mb-3 from-group"}>
      <CFormLabel>{field?.label}</CFormLabel>
      <div className="d-block">
        <input
          id={field?.id}
          {...field?.input}
          type="checkbox"
          className={field?.className}
        />
        <CFormLabel htmlFor={field?.id} className="m-2">
          {field?.checkboxText}
        </CFormLabel>
        <ErrorDisplay field={field} />
      </div>
    </div>
  );
}
