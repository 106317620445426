import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import store from './_reducers'
import App from './App';

import '@coreui/coreui/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'

ReactDOM.render(
  <Provider store={store}>
    <App  />
  </Provider>,
  document.getElementById('root')
);