import React, { useRef } from 'react';
import {
    CModal,
    CModalHeader,
    CModalBody,
    CButton,
    CRow,
    CCol,
    CCard,
    CCardHeader,
    CCardBody,
    CCardTitle,
    CBadge
} from '@coreui/react'
import { FiDownload } from 'react-icons/fi'
import { Doc } from '../../_services'
import Logo from '../../_assets/img/logo.png'

const b64img = "iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAIAAADTED8xAAADMElEQVR4nOzVwQnAIBQFQYXff81RUkQCOyDj1YOPnbXWPmeTRef+/3O/OyBjzh3CD95BfqICMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMK0CMO0TAAD//2Anhf4QtqobAAAAAElFTkSuQmCC"

export function PrintView(props) {

    const componentRef = useRef();

    return (
        <CModal
            visible={(props.printData !== false)}
            onDismiss={() => props.dispatchPrintData(false)}
            size="lg"
        >
            <CModalHeader onDismiss={() => props.dispatchPrintData(false)}>
                <CButton color="success" onClick={() => { Doc.createPdf(componentRef.current) }}>
                    <FiDownload /> PDF
                </CButton>
            </CModalHeader>
            <CModalBody ref={componentRef}>
                <PrintContent />
            </CModalBody>
        </CModal>
    )
}

const PrintContent = () => {
    return (
        <>
            <div className="text-center mb-3">
                <img src={Logo} alt="" />
                <p className="mb-0 fw-bold">Head Office</p>
                <p className="mb-0 fw-bold">No 122, 123 Rajanna Layout</p>
                <p className="mb-0 fw-bold">Agara Main Road, Horamavu Post Bangalore 560043</p>
                <p className="mb-0 fw-bold">Karnataka INDIA</p>
                <p className="mb-0 fw-bold">jpyardbangalore@gmail.com | 8197718007</p>
            </div>
            <hr />
            <CRow>
                <CCol>
                    INWARD# 31237,   17th July 2021 06:33 PM
                    TYPE# CABS   4 WHEELER
                </CCol>
                <CCol className="text-end fw-bold text-success">
                    STOCK IN
                </CCol>
            </CRow>
            <hr />
            <CRow className="d-flex">
                <CCol xs="4">
                    <CCard className="h-100 border-warning">
                        <CCardHeader className="bg-warning text-center fw-bold">
                            BRANCH
                        </CCardHeader>
                        <CCardBody>
                            <CCardTitle className="fw-bold">BANGALORE</CCardTitle>
                            Deepak <br />
                            No 122, 123 Rajanna Layout <br />
                            Agara Main Road, Horamavu Post <br />
                            Bangalore 560043 <br />
                            Karnataka <br />
                            INDIA <br />
                            jpyardbangalore@gmail.com 8197718007
                        </CCardBody>
                    </CCard>
                </CCol>

                <CCol xs="4" className="p-0">
                    <CCard className="h-100 border-warning">
                        <CCardHeader className="bg-warning text-center fw-bold">
                            LOCATION
                        </CCardHeader>
                        <CCardBody>
                            <CCardTitle className="fw-bold">BANJARA LAYOUT</CCardTitle>
                            DEEPAK<br />
                            57/2 & 57/3, HORAMAVU AGARA, BANJARA LAYOUT,BWSSB PLANT<br />
                            BANGALORE 560043<br />
                        </CCardBody>
                    </CCard>
                </CCol>

                <CCol xs="4">
                    <CCard className="h-100 border-warning">
                        <CCardHeader className="bg-warning text-center fw-bold">
                            CLIENT
                        </CCardHeader>
                        <CCardBody>
                            <CCardTitle className="fw-bold">BANJARA LAYOUT</CCardTitle>
                            DEEPAK<br />
                            57/2 & 57/3, HORAMAVU AGARA, BANJARA LAYOUT,BWSSB PLANT<br />
                            BANGALORE 560043<br />
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>

            <CRow className="mt-3">
                <CCol xs="6">
                    <table className="table table-bordered">
                        <tr>
                            <td><label className="fw-bold mb-1">Vehicle No</label></td>
                            <td><CBadge color="warning float-end">KA51AA3858</CBadge></td>
                        </tr>
                        <tr>
                            <td><label className="fw-bold mb-1">Manufacturer</label></td>
                            <td><CBadge color="warning float-end">Ford Motor Company</CBadge></td>
                        </tr>
                        <tr>
                            <td><label className="fw-bold mb-1">Color</label></td>
                            <td><CBadge color="warning float-end">Ford Motor Company</CBadge></td>
                        </tr>
                    </table>
                </CCol>
                <CCol xs="6">
                    <table className="table table-bordered">
                        <tr>
                            <td><label className="fw-bold mb-1">Fuel Type</label></td>
                            <td><CBadge color="warning float-end">Ford Motor Company</CBadge></td>
                        </tr>
                        <tr>
                            <td><label className="fw-bold mb-1">Vehicle Condition</label></td>
                            <td><CBadge color="warning float-end">Ford Motor Company</CBadge></td>
                        </tr>
                        <tr>
                            <td><label className="fw-bold mb-1">Driver Name</label></td>
                            <td><CBadge color="warning float-end">Ford Motor Company</CBadge></td>
                        </tr>
                    </table>

                </CCol>
            </CRow>
            <hr />
            <CRow>
                <CCol xs="6">
                    <CCard>
                        <CCardHeader className="text-center">
                            <img src={'data:image/png;base64,' + b64img} className="mw-100" alt="" />
                        </CCardHeader>
                        <CCardBody>
                            <CCardTitle>Front View</CCardTitle>
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol xs="6">
                    <CCard>
                        <CCardHeader className="text-center">
                            <img src={'data:image/png;base64,' + b64img} className="mw-100" alt="" />
                        </CCardHeader>
                        <CCardBody>
                            <CCardTitle>Back View</CCardTitle>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>

            <hr />
            <CRow className="align-items-end">
                <CCol>
                    <p>Remarks :</p>
                    <p>Reason : B2 TO MANGO</p>
                    BANJARA LAYOUT - Karnataka
                    <p className="fw-bold">Place</p>
                </CCol>
                <CCol className="text-end">
                    Deepak V
                    <p className="fw-bold">Inward Inspected By</p>
                </CCol>
            </CRow>
        </>
    )
}