import faker from 'faker'
import { sleep } from '../_services'
import {FiEye} from 'react-icons/fi'
import {CButton} from '@coreui/react'

export async function listDatas(dispatchPrintData) {
    
    await sleep(1000)
    const data = new Array(50).fill(null)
        .map(e => {
            return {
                vehicleno: faker.datatype.number(),
                location: faker.address.city(),
                branchno: faker.datatype.number(),
                inwardno: faker.datatype.number(),
                date: faker.datatype.string(),
                driver: faker.name.findName(),
                mobile: faker.datatype.number(),
                photo: faker.image.image(),
            }
        })
    const columns = [
        { label: 'Vechicle No', name: 'vehicleno', sortable: true, },
        { label: 'Location', name: 'location', sortable: true, },
        { label: 'Branch No', name: 'branchno', sortable: true, },
        { label: 'Inward No', name: 'inwardno', sortable: true, },
        { label: 'Date', name: 'date', sortable: true,/* right: true,*/ },
        { label: 'Driver', name: 'driver', sortable: true, },
        { label: 'Mobile', name: 'mobile', sortable: true, },
        { label: 'Photo', name: 'photo', sortable: true,  options:{
            customBodyRender:(value, tableMeta, updateValue)=>{return <a href={value} target="blank">view</a>}
        }},
        { label: 'Actions', name: '', sortable: true, options:{
            customBodyRender:()=>{return <CButton variant="ghost" className="p-0" onClick={()=>{dispatchPrintData({ab:"abcd", 12:"one two"}) }}><FiEye /></CButton>}
        }},
    ];

    return { data, columns }
}