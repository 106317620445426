import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./_components";
import { Login, Dashboard, ParkingList, Location, Branch } from "./Pages";

import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/parking-list" component={ParkingList} />
        <PrivateRoute path="/branch" component={Branch} />
        <PrivateRoute path="/location" component={Location} />
        <Route path="/login" component={Login} />
        <Route path="/" component={Login} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
