import React, { useState } from 'react'
import { CContainer, CCol } from '@coreui/react'
import { AppHeader } from './AppHeader'
import { AppSidebar } from './AppSidebar'
import './Layout.css'

export function Layout(props) {

    const [sidebarShow, setSidebarShow] = useState(window.innerWidth > 768 ? true : false)

    return (
        <div className="vh-100 ">
            <AppHeader sidebarShow={sidebarShow} setSidebarShow={setSidebarShow} />
            <CContainer fluid className="main-container d-sm-flex p-0">
                {/* <CRow className="h-100"> */}
                <CCol sm="12" md="3" lg="2" className={'p-0 app-sidebar ' + (sidebarShow ? '' : 'collapse-menu')}>
                    <AppSidebar />
                </CCol>
                <CCol className="p-2 table-responsive">
                    {props.children}
                </CCol>
                {/* </CRow> */}
            </CContainer>
        </div>
    )
}
