import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Layout } from '../Layout'


export const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Layout>
            <Route {...rest} render={props => (
                localStorage.getItem('ymsuser') ? <Component {...props} /> : <Redirect to='/login' />
            )} />
        </Layout>
    )
}