import { combineReducers, createStore } from "redux";
import { reducer as formReducer } from "redux-form";
import { alert } from "./alert";
import { user } from "./user";
import { parking } from "./parking";
import { loading } from "./loading";
import { locations } from "./locations";
import { branches } from "./branch";

const reducers = combineReducers({
  form: formReducer,
  alert,
  user,
  parking,
  loading,
  locations,
  branches,
});

const store = createStore(reducers);

export default store;
