import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
} from "@coreui/react";
import { RiCloseFill } from "react-icons/ri";
import { PageLoader } from "../../_components";
import { allBranches, loadBranch, pageLoading } from "../../_actions";
import { getAllBranches, createBranch, editBranch } from "../../_services";
import BranchForm from "./BranchForm";
import { FloatingButton } from "../../_components";

export const Branch = () => {
  const branchList = useSelector((state) => state.branches.allBranches);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  async function addBranch(values) {
    dispatch(pageLoading(true));
    const result = await (values.secret_id
      ? editBranch(values)
      : createBranch(values));
    if (result && result.success) {
      const branches = await getAllBranches(viewBranch);
      dispatch(allBranches(branches));
      setVisible(false);
    } else {
      //show alert
      console.log("error in add");
    }
    dispatch(pageLoading(false));
  }

  const viewBranch = (meta) => {
    dispatch(pageLoading(true));
    const { rowIndex } = meta;
    console.log(branchList);
    const data = branchList?.data[rowIndex];
    dispatch(loadBranch(data));
    setVisible(true);
    dispatch(pageLoading(false));
  };

  useEffect(() => {
    async function asyncTasks() {
      dispatch(pageLoading(true));
      const branches = await getAllBranches(viewBranch);
      dispatch(allBranches(branches));
      dispatch(pageLoading(false));
    }
    asyncTasks();
  }, []);

  const { data, columns } = branchList;
  const options = { selectableRows: "none", responsive: "vertical" };

  return (
    <PageLoader>
      <FloatingButton
        onClick={() => {
          dispatch(loadBranch([]));
          setVisible(!visible);
        }}
      />
      <MUIDataTable
        title={"Branch"}
        data={data}
        columns={columns}
        options={options}
      />

      <CModal visible={visible} size="xl" onClose={() => setVisible(false)}>
        <CModalHeader>
          <CModalTitle>Branch</CModalTitle>
          <CButton color="" onClick={() => setVisible(false)}>
            <RiCloseFill />
          </CButton>
        </CModalHeader>
        <CModalBody>
          <BranchForm
            addBranch={addBranch}
            closeForm={() => setVisible(false)}
            // data={branchData}
          />
        </CModalBody>
      </CModal>
    </PageLoader>
  );
};
