import { savePDF } from '@progress/kendo-react-pdf';

class DocService {
  createPdf = (html) => {
    savePDF(html, { 
      paperSize: 'auto',
      fileName: 'deatils.pdf',
      margin: 40
    })
  }
}

export const Doc = new DocService();