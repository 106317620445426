import { API_URL } from "../_constants";
import axios from "axios";

import { CButton } from "@coreui/react";
import { FiEye } from "react-icons/fi";

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("ymsadmintoken");
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";

export async function getAllLocations() {
  const data = await axios
    .post("location")
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });

  const columns = [
    { label: "Location Name", name: "LocationName", sortable: true },
    { label: "Short Name", name: "ShortName", sortable: true },
    {
      label: "Actions",
      name: "",
      sortable: true,
      options: {
        customBodyRender: () => {
          return (
            <CButton
              variant="ghost"
              className="p-0"
              onClick={() => {
                console.log("loc");
              }}
            >
              <FiEye />
            </CButton>
          );
        },
      },
    },
  ];
  return { data, columns };
}

export async function createLocation(values) {
  const data = await axios
    .post("location/add", values)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
  return data;
}
