import React from 'react'
import { connect } from 'react-redux'
import MUIDataTable from "mui-datatables";

import { listDatas } from '../../_services'
import { parking, pageLoading, printData } from '../../_actions'
import { PageLoader } from '../../_components'
import { PrintView } from './PrintView'

class ParkingListComponent extends React.Component {

    constructor(props) {
        super(props)
        this.getData = this.getData.bind(this)
    }

    async getData() {
        this.props.dispatchPageLoading(true)
        const response = await listDatas(this.props.dispatchPrintData)
        this.props.dispatchParkingList(response)
        this.props.dispatchPageLoading(false)
    }

    componentDidMount() {
        this.getData()
    }

    render() {
        const { data, columns } = this.props.parkingList
        const options = { selectableRows: 'none' }
        return (
            <PageLoader>
                <MUIDataTable
                    title={"Parking Details"}
                    data={data}
                    columns={columns}
                    options={options}
                />
                <PrintView
                    printData={this.props.printData}
                    dispatchPrintData={this.props.dispatchPrintData}
                />
            </PageLoader>
        )

    }
}

const mapStateToProps = state => {
    return {
        parkingList: state.parking.parkingList,
        printData: state.parking.printData
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatchParkingList: payload => { dispatch(parking(payload)) },
        dispatchPrintData: payload => { dispatch(printData(payload)) },
        dispatchPageLoading: payload => { dispatch(pageLoading(payload)) }
    }
}

export const ParkingList = connect(
    mapStateToProps,
    mapDispatchToProps
)(ParkingListComponent)