import { ALL_BRANCHES, LOAD_BRANCH } from "../_constants";

export function branches(state = { allBranches: [], loadBranch: [] }, action) {
  if (action.type === ALL_BRANCHES) {
    return { ...state, allBranches: action.payload };
  } else if (action.type === LOAD_BRANCH) {
    return { ...state, loadBranch: action.payload };
  }
  return state;
}
