import React from 'react'
import { useHistory } from 'react-router-dom'
import { CHeader, CHeaderBrand, CHeaderToggler } from '@coreui/react'
import { HiMenu, HiOutlineLogout } from 'react-icons/hi'

function logout(history){
    localStorage.removeItem('ymsuser')
    history.push('/login')
}
export function AppHeader(props) {
    const history = useHistory()
    const user = JSON.parse(localStorage.getItem('ymsuser'))
    return (
        <CHeader position="sticky" className="bg-taxi border-0">
            <div>
                <CHeaderBrand className="text-white d-none d-sm-inline-block">JP Parking Yard</CHeaderBrand>
                <CHeaderToggler onClick={() => { props.setSidebarShow(!props.sidebarShow) }} >
                    <HiMenu />
                </CHeaderToggler >
                <label className='text-uppercase '>{user.name}</label>
            </div>
            <button className="btn text-white" onClick={()=>{logout(history)}}>
                <HiOutlineLogout />
            </button>
        </CHeader>
    )
}