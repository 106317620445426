import { React, useState } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { CButton } from "@coreui/react";
import { InputField, SelectField, CheckboxField } from "../../_components";
import { Validators } from "../../_helpers";
let BranchForm = (props) => {
  const { handleSubmit, addBranch, closeForm } = props;
  return (
    <form onSubmit={handleSubmit(addBranch)} className="row">
      <Field
        name="BranchName"
        label="Branch Name"
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
        type="text"
        required
      />
      <Field
        name="ShortName"
        type="text"
        label="Short Name"
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
        required
      />
      <Field
        name="FirstAddress"
        type="text"
        label="Address Line 1 "
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="LastAddress"
        type="text"
        label="Address Line 2 "
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="City"
        type="text"
        label="City "
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="Pincode"
        type="text"
        label="Pincode "
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="Country"
        type="text"
        label="Country"
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="State"
        type="text"
        label="State"
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="Email"
        type="email"
        label="Email"
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="Phone"
        type="text"
        label="Phone"
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="ContactPerson"
        type="text"
        label="Contact Person "
        required
        component={InputField}
        responsive="col-12 col-sm-12 col-md-12 col-lg-4"
      />
      <Field
        name="ContactPersonEmail"
        type="email"
        label="Contact Email"
        required
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="ContactPersonPhone"
        type="text"
        label="Contact Phone"
        required
        maxLength="10"
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="PAN"
        type="text"
        label="PAN"
        required
        maxLength="10"
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="GST"
        type="text"
        label="GSTIN"
        required
        component={InputField}
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="IsHeadOffice"
        label="Is Head Office?"
        checkboxText="Yes"
        component={CheckboxField}
        id="IsHeadOffice"
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <Field
        name="Status"
        label="Status"
        checkboxText="Active"
        component={CheckboxField}
        id="Status"
        responsive="col-12 col-sm-6 col-md-6 col-lg-4"
      />
      <div className="col-sm-12 text-center">
        <CButton type="submit" color="success">
          Save
        </CButton>
        <CButton
          type="button"
          style={{ marginLeft: "10px" }}
          color="danger"
          onClick={(e) => {
            e.preventDefault();
            closeForm();
          }}
        >
          Cancel
        </CButton>
      </div>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ["BranchName", "ShortName"];
  requiredFields.map((fld) => {
    if (Validators.empty(values[fld])) {
      errors[fld] = "Required";
    }
  });
  if (
    !Validators.isEmail(values["ContactPersonEmail"]) &&
    !Validators.empty(values["ContactPersonEmail"])
  ) {
    errors["ContactPersonEmail"] = "Invalid Email";
  }
  return errors;
};

BranchForm = reduxForm({
  form: "addBranch",
  validate,
})(BranchForm);

BranchForm = connect(
  (state) => ({
    initialValues: state.branches.loadBranch, // pull initial values from account reducer
  })
  // { load: loadAccount } // bind account loading action creator
)(BranchForm);

export default BranchForm;
