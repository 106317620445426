import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { CButton, CAlert, CSpinner } from '@coreui/react'

import { InputField } from '../../_components'

function LoginForm(props) {
    return (
        <>
            {props.loginError && <CAlert color="danger">{props.loginError}</CAlert>}
            <form onSubmit={props.handleSubmit}>
                <Field type="text" name="Username" label="Username" component={InputField} />
                <Field type="password" name="Password" label="Password" component={InputField} />
                <CButton type="submit" color="success" className="float-end" disabled={props.loginLoading}>
                    Login
                    {props.loginLoading && <CSpinner variant="border" size="sm" />}
                </CButton>
            </form>
        </>
    )
}

function validateform(values) {
    const errors = {}
    if (!values.username) {
        errors.username = "Username is required";
    }

    if (!values.password) {
        errors.password = "Password is required";
    }
    console.log(errors)
    return errors;
}

export default reduxForm({
    form: 'loginform',
    validate: validateform
})(LoginForm)
